var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fDate } from '../../../utils';
import axios from '../../../utils/axios';
var PATH_ROOT = '/operations';
// POST ONE
export var createExpense = createAsyncThunk('Expenses/POST', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, expense, response, err_1;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                expense = payload.expense;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.post("".concat(PATH_ROOT), __assign(__assign({}, expense), { admin: (_a = expense === null || expense === void 0 ? void 0 : expense.admin) === null || _a === void 0 ? void 0 : _a._id, office: (_b = expense === null || expense === void 0 ? void 0 : expense.office) === null || _b === void 0 ? void 0 : _b._id }))];
            case 2:
                response = _c.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_1 = _c.sent();
                return [2 /*return*/, Promise.reject(err_1.message ? err_1.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
// UPDATE ONE
export var updateExpense = createAsyncThunk('Expense/UPDATE', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, expenseId, body, response, err_2;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                expenseId = payload.expenseId, body = payload.body;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.patch("".concat(PATH_ROOT, "/").concat(expenseId), __assign(__assign({}, body), { admin: (_a = body === null || body === void 0 ? void 0 : body.admin) === null || _a === void 0 ? void 0 : _a._id, office: (_b = body === null || body === void 0 ? void 0 : body.office) === null || _b === void 0 ? void 0 : _b._id }))];
            case 2:
                response = _c.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_2 = _c.sent();
                return [2 /*return*/, Promise.reject(err_2.message ? err_2.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
// GET ALL
export var getExpenses = createAsyncThunk('Expenses/GETALL', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, page, _a, order, _b, orderBy, _c, limit, filterType, filterAdmin, filterOffice, endDate, startDate, search, params, response, err_3;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                page = payload.page, _a = payload.order, order = _a === void 0 ? 'desc' : _a, _b = payload.orderBy, orderBy = _b === void 0 ? 'createdAt' : _b, _c = payload.limit, limit = _c === void 0 ? 10 : _c, filterType = payload.filterType, filterAdmin = payload.filterAdmin, filterOffice = payload.filterOffice, endDate = payload.endDate, startDate = payload.startDate, search = payload.search;
                params = __assign(__assign(__assign(__assign(__assign({ page: page + 1, limit: limit, sort: order === 'desc' ? "-".concat(orderBy) : orderBy }, (filterType !== 'all' ? { type: filterType } : {})), (search ? { search: search } : {})), (filterAdmin ? { admin: filterAdmin === null || filterAdmin === void 0 ? void 0 : filterAdmin._id } : {})), (filterOffice ? { office: filterOffice === null || filterOffice === void 0 ? void 0 : filterOffice._id } : {})), (startDate && endDate
                    ? {
                        startDate: fDate(startDate, 'yyyy-MM-dd'),
                        endDate: fDate(endDate, 'yyyy-MM-dd'),
                    }
                    : {}));
                _d.label = 1;
            case 1:
                _d.trys.push([1, 4, , 5]);
                return [4 /*yield*/, axios.get("".concat(PATH_ROOT), { params: params })];
            case 2:
                response = _d.sent();
                return [4 /*yield*/, response.data];
            case 3:
                data = _d.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 4:
                err_3 = _d.sent();
                return [2 /*return*/, Promise.reject(err_3.message ? err_3.message : data === null || data === void 0 ? void 0 : data.message)];
            case 5: return [2 /*return*/];
        }
    });
}); });
// GET ONE
export var getOneExpense = createAsyncThunk('Expense/GetONE', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, expenseId, response, err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                expenseId = payload.expenseId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.get("".concat(PATH_ROOT, "/").concat(expenseId))];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_4 = _a.sent();
                return [2 /*return*/, Promise.reject(err_4.message ? err_4.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
// DELETE ONE
export var deleteOneExpense = createAsyncThunk('Expense/DELETE', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, expenseId, response, err_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                expenseId = payload.expenseId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.delete("".concat(PATH_ROOT, "/").concat(expenseId))];
            case 2:
                response = _a.sent();
                data = response.data;
                if (response.status === 200) {
                    return [2 /*return*/, data];
                }
                throw new Error(response.statusText);
            case 3:
                err_5 = _a.sent();
                return [2 /*return*/, Promise.reject(err_5.message ? err_5.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
